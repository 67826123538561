import classNames from "../../consts/classNames";

const PrivacyPolicy = () => {
  return (
    <div
      className={`${classNames.containerClass} max-w-[1024px] mt-6 lg:mt-10`}
    >
      <h1 className="text-center text-3xl lg:text-5xl font-bold text-violet-500">
        Privacy Policy
      </h1>
      <div className="mt-5 lg:mt-6 space-y-5">
        <p>
          LBS SERVICES GLOBAL LTD, a private limited company, founded in Latvia,
          ("us", "we", or "our") operates the https://Game-Boosts.com/ website
          (the "Service").
        </p>
        <p>
          LBS SERVICES GLOBAL LTD, is the Controller of your personal data
          unless otherwise stated below.
        </p>
        <p>
          We have nominated a Data Protection Officer, and you can contact him
          at Game-Boosts.com or via our postal address. Please mark the envelope
          ‘Data Protection Officer’. Our postal address is 2, Frederick Street,
          Kings Cross, London, WC1X 0ND,UNITED KINGDOM
        </p>
        <p>
          This page informs you of our policies regarding the collection, use
          and disclosure of Personal Information when you use our Service.
        </p>
        <p>
          We will not use or share your information with anyone except as
          described in this Privacy Policy.
        </p>
        <p>
          We use your Personal Information for providing and improving the
          Service. By using the Service, you agree to the collection and use of
          information in accordance with this policy. Unless otherwise defined
          in this Privacy Policy, terms used in this Privacy Policy have the
          same meanings as in our Terms and Conditions, accessible at
          https://Game-Boosts.com.
        </p>
        <p>
          Information Collection And Use While using our Service, we may ask you
          to provide us with certain personally identifiable information that
          can be used to contact or identify you. Personally identifiable
          information may include, but is not limited to, your email address,
          full name, payment information, limited identification data of the
          device like the communication and correspondence records such as when
          you engage with our customer service agents or chat with boosters
          ("Personal Information").
        </p>
        <p>
          We only work with trusted partners and authorities. We only share when
          there is a proper reason to do so. We limit sharing to only that which
          is required. We do not sell your personal information.
        </p>
        <p>
          Log Data <br />
          We may also collect information that your browser sends whenever you
          visit our Service ("Log Data"). This Log Data may include information
          such as your computers' Internet Protocol ("IP") address, browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages and other
          statistics.
        </p>
        <p>
          In addition, we may use third party services such as Google Analytics
          that collect, monitor and analyze this type of information. These
          third party service providers have their own privacy policies
          addressing how they use such information.
        </p>
        <p>
          Cookies <br /> Cookies are files with small amount of data, which may
          include an anonymous unique identifier. Cookies are sent to your
          browser from a web site and stored on your computers' hard drive.
        </p>
        <p>
          Cookies are files with small amount of data, which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          web site and stored on your computers' hard drive.
        </p>
        <p>
          Service Providers <br />
          We may employ third party companies and individuals to facilitate our
          Service, to provide the Service on our behalf, to perform
          Service-related services or to assist us in analyzing how our Service
          is used.
        </p>
        <p>
          These third parties have access to a portion of your Personal
          Information only to perform these tasks and are obligated not to
          disclose or use it for any other purpose.
        </p>
        <p>
          Communications <br />
          We may use your Personal Information to contact you with transactional
          emails, newsletters, marketing or promotional materials and other
          information that may be of interest to you. You may opt out of
          receiving any, or all, of these communications from us by following
          the unsubscribe link or instructions provided in any email we send.
        </p>
        <p>
          Security <br />
          The security of your Personal Information is important to us.
          Although, no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While we strive to use commercially
          acceptable means to protect your Personal Information, we cannot
          guarantee its absolute security.
        </p>
        <p>
          Any personal data collected in the course of providing our services is
          transferred to and stored in the data centres of DigitalOcean, LLC,
          Cloudflare, Inc. and/or Amazon Web Services, Inc., which are located
          in the territories of Member States of the European Union. Only
          authorised employees and service providers of LBS SERVICES GLOBAL LTD.
          and its partners (local subsidiaries, representatives, affiliates,
          agents etc) have access to the personal data and they may access the
          data only for the purpose of resolving issues associated with the use
          of the services (including disputes regarding our services).
        </p>
        <p>
          International Transfer
          <br />
          Your information, including Personal Information, may be transferred
          to, and maintained on, computers located outside of your state,
          province, country or other governmental jurisdiction where the data
          protection laws may differ than those from your jurisdiction.
        </p>
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>
          Links To Other Sites
          <br />
          Our Service may contain links to other sites that are not operated by
          us. If you click on a third party link, you will be directed to that
          third party's site. We strongly advise you to review the Privacy
          Policy of every site you visit.
        </p>
        <p>
          We have no control over, and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <p>
          Children's Privacy <br /> Our Service does not address anyone under
          the age of 13 ("Children").
        </p>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page along
          with updating the last updated date shown on this page.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <p>
          Contact Us
          <br />
          Disputes relating to the processing of personal data are resolved
          through customer support in the first instance. You have the right to
          contact LBS SERVICES GLOBAL LTD Data Protection Officer
          (info@Game-Boosts.com).
        </p>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          via our email address, live chat or the legal address mentioned above.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
